@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?ycb3uu') format('truetype'),
    url('fonts/icomoon.woff?ycb3uu') format('woff'),
    url('fonts/icomoon.svg?ycb3uu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-external-link:before {
  content: "\e900";
}
.icon-users-sm:before {
  content: "\e99d";
}
.icon-user-add-sm:before {
  content: "\e99f";
}
.icon-plus-bold:before {
  content: "\e9a0";
}
.icon-thumbs-down:before {
  content: "\e9a1";
}
.icon-thumbs-up:before {
  content: "\e9a2";
}
.icon-sms:before {
  content: "\e9a3";
}
.icon-edit:before {
  content: "\e9a5";
}
.icon-tag:before {
  content: "\e9a6";
}
.icon-qr_code_scanner:before {
  content: "\e9a7";
}
.icon-droplet:before {
  content: "\e9a8";
}
.icon-wind:before {
  content: "\e9a9";
}
.icon-book-open:before {
  content: "\e9aa";
}
.icon-grid:before {
  content: "\e9ab";
}
.icon-sun:before {
  content: "\e9ac";
}
.icon-Gemini:before {
  content: "\e9ad";
}
.icon-add:before {
  content: "\f2c7";
}
.icon-alarm:before {
  content: "\e9ae";
}
.icon-alarm_add:before {
  content: "\e9e3";
}
.icon-alarm_on:before {
  content: "\e9af";
}
.icon-alert:before {
  content: "\f35b";
}
.icon-apple:before {
  content: "\e9b1";
  color: #999;
}
.icon-attachment:before {
  content: "\f367";
}
.icon-attachment-2:before {
  content: "\e9b2";
}
.icon-attachment1:before {
  content: "\f368";
}
.icon-ban:before {
  content: "\e9b3";
}
.icon-bell:before {
  content: "\e9b5";
}
.icon-bell_off:before {
  content: "\e9b6";
}
.icon-bell_on:before {
  content: "\e9b7";
}
.icon-cake:before {
  content: "\e9b8";
}
.icon-camera:before {
  content: "\e9b9";
}
.icon-camera-off:before {
  content: "\e9bb";
}
.icon-caret-right:before {
  content: "\e9bd";
}
.icon-chat:before {
  content: "\f11e";
}
.icon-chat-ing:before {
  content: "\f11d";
}
.icon-checkbox:before {
  content: "\f371";
}
.icon-checkbox-o:before {
  content: "\f373";
}
.icon-checkbox1:before {
  content: "\f372";
}
.icon-chevron-down:before {
  content: "\f123";
}
.icon-chevron-left:before {
  content: "\f124";
}
.icon-chevron-right:before {
  content: "\f126";
}
.icon-chevron-up:before {
  content: "\f128";
}
.icon-clock:before {
  content: "\e9be";
}
.icon-close:before {
  content: "\e9bf";
}
.icon-cogs-2:before {
  content: "\e9c1";
}
.icon-coin:before {
  content: "\e9c3";
}
.icon-coins:before {
  content: "\e9c5";
}
.icon-delete_forever:before {
  content: "\e9c6";
}
.icon-desktop:before {
  content: "\e9c7";
}
.icon-done:before {
  content: "\f383";
}
.icon-done-all:before {
  content: "\f382";
}
.icon-download:before {
  content: "\e9c8";
}
.icon-eye:before {
  content: "\e9c9";
}
.icon-eye-off:before {
  content: "\e9ca";
}
.icon-film:before {
  content: "\e9cb";
}
.icon-g_translate:before {
  content: "\e9cc";
}
.icon-gift:before {
  content: "\e9cd";
}
.icon-global-o:before {
  content: "\e9cf";
}
.icon-globe:before {
  content: "\f38c";
}
.icon-help:before {
  content: "\e9d1";
}
.icon-help_outline:before {
  content: "\e9d2";
}
.icon-hidden:before {
  content: "\f306";
}
.icon-high_quality:before {
  content: "\e9d3";
}
.icon-inbox3:before {
  content: "\e9d5";
}
.icon-keyboard:before {
  content: "\e9d7";
}
.icon-keyboard_hide:before {
  content: "\e9d9";
}
.icon-laptop:before {
  content: "\e9da";
}
.icon-lightbulb:before {
  content: "\e9db";
}
.icon-medium:before {
  content: "\e9dd";
}
.icon-mic:before {
  content: "\e9e7";
}
.icon-mic-off:before {
  content: "\e9e6";
}
.icon-more-vertical:before {
  content: "\f398";
}
.icon-ondemand_video:before {
  content: "\e9de";
}
.icon-pause:before {
  content: "\e9e9";
}
.icon-pin:before {
  content: "\e9df";
}
.icon-play-circle:before {
  content: "\e9e1";
}
.icon-play_arrow:before {
  content: "\e9e8";
}
.icon-remove:before {
  content: "\f3a9";
}
.icon-search-thin:before {
  content: "\e9e2";
}
.icon-send:before {
  content: "\e9e4";
}
.icon-share:before {
  content: "\e9e5";
}
.icon-shop:before {
  content: "\e9ea";
}
.icon-shopping-bag:before {
  content: "\e9eb";
}
.icon-shopping-cart:before {
  content: "\e9ec";
}
.icon-signin:before {
  content: "\e9ed";
}
.icon-translate:before {
  content: "\e9ee";
}
.icon-visible:before {
  content: "\f133";
}
.icon-wifi-off:before {
  content: "\e9ef";
}
.icon-more:before {
  content: "\e9f0";
}
.icon-radio-unchecked:before {
  content: "\e901";
}
.icon-radio-checked-alt:before {
  content: "\e902";
}
.icon-radio-checked:before {
  content: "\e903";
}
.icon-pinterest:before {
  content: "\e904";
}
.icon-credit-card:before {
  content: "\e977";
}
.icon-open-letter:before {
  content: "\e978";
}
.icon-whatsapp:before {
  content: "\e979";
}
.icon-paragraphs:before {
  content: "\e97a";
}
.icon-plus-inline:before {
  content: "\e97b";
}
.icon-Aquarius:before {
  content: "\e97c";
}
.icon-Aries:before {
  content: "\e97d";
}
.icon-Cancer:before {
  content: "\e97e";
}
.icon-Capricorn:before {
  content: "\e97f";
}
.icon-Leo:before {
  content: "\e981";
}
.icon-Libra:before {
  content: "\e983";
}
.icon-Pisces:before {
  content: "\e985";
}
.icon-Sagittarius:before {
  content: "\e987";
}
.icon-Scorpio:before {
  content: "\e989";
}
.icon-Taurus:before {
  content: "\e98a";
}
.icon-Virgo:before {
  content: "\e98b";
}
.icon-arrows1:before {
  content: "\e98c";
}
.icon-attachment-3:before {
  content: "\f0c6";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-bookmark-o:before {
  content: "\f097";
}
.icon-check-alt:before {
  content: "\e98d";
}
.icon-checkbox-checked:before {
  content: "\e98e";
}
.icon-checkbox-unchecked:before {
  content: "\e98f";
}
.icon-checkmark:before {
  content: "\e991";
}
.icon-cogs:before {
  content: "\f013";
}
.icon-envelope1:before {
  content: "\f003";
}
.icon-expand:before {
  content: "\e992";
}
.icon-hash:before {
  content: "\e993";
}
.icon-hot:before {
  content: "\e994";
}
.icon-images:before {
  content: "\e9bc";
}
.icon-info:before {
  content: "\e9c0";
}
.icon-magic-wand:before {
  content: "\e995";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-quotes-left:before {
  content: "\e996";
}
.icon-quotes-right:before {
  content: "\e997";
}
.icon-settings:before {
  content: "\e9dc";
}
.icon-slowly:before {
  content: "\205f";
}
.icon-stamp:before {
  content: "\2008";
}
.icon-stats:before {
  content: "\e998";
}
.icon-user:before {
  content: "\f00e";
}
.icon-user-plus:before {
  content: "\2007";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-windows:before {
  content: "\e999";
}
.icon-plus:before {
  content: "\e99a";
}
.icon-slowly-logo:before {
  content: "\e99b";
}
.icon-shield-outline:before {
  content: "\e905";
}
.icon-shield:before {
  content: "\e960";
}
.icon-x:before {
  content: "\e961";
}
.icon-telegram:before {
  content: "\e962";
}
.icon-heart:before {
  content: "\e963";
}
.icon-heart-o:before {
  content: "\e964";
}
.icon-trophy:before {
  content: "\e965";
}
.icon-android:before {
  content: "\e990";
}
.icon-arrow-down:before {
  content: "\e966";
}
.icon-arrow-up:before {
  content: "\e99c";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-beard:before {
  content: "\e967";
}
.icon-chrome:before {
  content: "\e99e";
}
.icon-circle:before {
  content: "\f111";
}
.icon-circle-o:before {
  content: "\f10c";
}
.icon-cloth:before {
  content: "\e980";
}
.icon-cloud-download:before {
  content: "\e9a4";
}
.icon-dice:before {
  content: "\e968";
}
.icon-envelope-o:before {
  content: "\f005";
}
.icon-envelope-open-o:before {
  content: "\f2b7";
}
.icon-eyebrow:before {
  content: "\e969";
}
.icon-eyes:before {
  content: "\e96a";
}
.icon-face:before {
  content: "\e96b";
}
.icon-facebook:before {
  content: "\e96c";
}
.icon-file-image-o:before {
  content: "\e9b0";
}
.icon-glasses:before {
  content: "\e96d";
}
.icon-google:before {
  content: "\e9b4";
}
.icon-hair:before {
  content: "\e96e";
}
.icon-hat:before {
  content: "\e982";
}
.icon-image:before {
  content: "\e9ba";
}
.icon-info-circle:before {
  content: "\e9c2";
}
.icon-instagram:before {
  content: "\e986";
}
.icon-keyboard-o:before {
  content: "\e9c4";
}
.icon-linkedin:before {
  content: "\e96f";
}
.icon-lock:before {
  content: "\f023";
}
.icon-mars:before {
  content: "\f222";
}
.icon-mouth:before {
  content: "\e984";
}
.icon-nose:before {
  content: "\e970";
}
.icon-phone:before {
  content: "\e9ce";
}
.icon-phone-square:before {
  content: "\e9d0";
}
.icon-pin-a-letter:before {
  content: "\e971";
}
.icon-print:before {
  content: "\e972";
}
.icon-push-pin:before {
  content: "\e973";
}
.icon-refresh:before {
  content: "\e9d4";
}
.icon-reply:before {
  content: "\e9d6";
}
.icon-safari:before {
  content: "\e9d8";
}
.icon-search:before {
  content: "\f002";
}
.icon-smile:before {
  content: "\e974";
}
.icon-sort:before {
  content: "\f161";
}
.icon-stamp-small-01:before {
  content: "\e975";
}
.icon-star:before {
  content: "\f008";
}
.icon-star-o:before {
  content: "\f00a";
}
.icon-th:before {
  content: "\f00c";
}
.icon-trash-o:before {
  content: "\e9e0";
}
.icon-twitter1:before {
  content: "\e988";
}
.icon-unlock:before {
  content: "\f13e";
}
.icon-user-circle:before {
  content: "\e976";
}
.icon-venus:before {
  content: "\f221";
}
.icon-stars:before {
  content: "\e9f1";
}
.icon-file-gif:before {
  content: "\e9f2";
}
.icon-minimize:before {
  content: "\e9f3";
}
.icon-news:before {
  content: "\e9f4";
}
.icon-paper:before {
  content: "\e9f4";
}
.icon-copy:before {
  content: "\e9f5";
}
.icon-calendar:before {
  content: "\e9f6";
}
.icon-home:before {
  content: "\e9f7";
}
